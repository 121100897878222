import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

import PageLayout from '../../components/PageLayout';
import SEO from '../../components/seo';
import '../../App.css';
require('typeface-rubik');

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Point = styled.h2`
  font-weight: 500;
  font-size: 25px;
  margin-top: 0px;
`;

const Container = styled(FlexCol)`
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const highlight = {
    background: '#b4f2e1',
    color: '#000000',
    textDecoration: 'none'
};

const IndexPage = () => {
    const [screenWidth, setScreenWidth] = useState('70%');
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth < 480) {
            setScreenWidth('100%');
        }
    }, []);

    return (
        <PageLayout>
            <SEO
                title={'Webkit - Ultimate Starter Kit For Non-Technical SEO Consultants'}
                description={'Launch your SEO compliant content website in 2 minutes for free'}
                keywords={[
                    'SEO Analyst',
                    'SEO Technical',
                    'SEO Developer',
                    'SEO Analytics'
                ]}
                image={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Ftoolkit.jpg?alt=media&token=e3b4a777-d719-4a8e-87d1-1dd955fa2978'}
            />
            <Container style={{backgroundColor: '#2ecc7112'}}>
                <Banner windowWidth={windowWidth}/>
            </Container>

            <Container style={{margin: '0 auto', width: screenWidth}}>
                <h1 style={{textAlign: 'center'}}>
                    {`Way too long technical checklist to SEO your website? 😥`}
                </h1>
            </Container>

            <div style={{textAlign: 'center'}}>
                <Container>
                    <picture>
                        <source
                            style={{
                                width: '90%',
                                borderImageWidth: '2px',
                                borderColor: '#00000',
                                paddingTop: '0px'
                            }}
                            type='image/avif'
                            srcSet={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Ftoolkit.avif?alt=media&token=8f95b017-3dcb-48e3-897a-942c6b33b675'}
                        />
                        <img
                            style={{
                                width: '90%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            alt='squarespace whatsapp'
                            src={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Ftoolkit.jpg?alt=media&token=e3b4a777-d719-4a8e-87d1-1dd955fa2978'}
                        />
                    </picture>
                </Container>
            </div>

            <br/>

            <div>
                <Container style={{margin: '0 auto', width: screenWidth, backgroundColor: '#2ecc7112'}}>
                    <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                        Hey SEO consultants,
                    </div>
                    <br/>
                    <div style={{fontSize: '25px', fontWeight: '400', marginTop: '20px', lineHeight: '1.3', textAlign: 'center'}}>
                        <h1 style={{marginTop: '0px'}}>Launch your SEO compliant content website in 2 minutes for free 🚀</h1>
                        Do not let your lack of coding skills be a setback for you. Webkit gets
                        <b style={highlight}> all your technical requirements for SEO covered</b>. You focus on content writing. Webkit takes care of the rest. 🙂
                    </div>
                </Container>
            </div>

            <Container style={{margin: '0 auto', width: screenWidth}}>
                <h1 style={{textAlign: 'center'}}>
                    {`What exactly Webkit let you do ?`}
                </h1>
            </Container>

            <div>
                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <h2 style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                        {`✅ Write markdown files that are SEO compliant by default`}
                    </h2>
                </Container>
                <div style={{textAlign: 'center'}}>
                    <Container>
                        <img
                            style={{
                                width: '70%'
                            }}
                            alt='seo-pipes'
                            src={require(`../../images/markdown.svg`)}
                        />
                    </Container>
                </div>
            </div>

            <Container style={{margin: '0 auto', width: screenWidth}}>
                <div className='rcards'>
                    <div className='rcard'>
                        <h2>✅ Embed youtube videos in markdown without slowing down page loading time</h2>
                        <img
                            alt='seo-pipes'
                            src={require('../../images/youtube.svg')}
                        />
                    </div>
                    <div className='rcard'>
                        <h2>✅ Connect with your custom domain and make your site go live for free forever</h2>
                        <img
                            alt='seo-pipes'
                            src={require('../../images/domain.svg')}
                        />
                    </div>
                </div>
            </Container>

            <Container style={{margin: '0 auto', width: screenWidth}}>
                <div className='rcards'>
                    <div className='rcard'>
                        <h2>✅ Caches pages for offline availability so your users don't get to see dino</h2>
                        <img
                            alt='seo-pipes'
                            src={require('../../images/offline.svg')}
                        />
                    </div>
                    <div className='rcard'>
                        <h2>✅ Users can add your site to the home screen as if its a mobile app and improve retention </h2>
                        <img
                            alt='seo-pipes'
                            src={require('../../images/homescreen.svg')}
                        />
                    </div>
                </div>
            </Container>

            <Container style={{margin: '0 auto', width: screenWidth}}>
                <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                    {`And much more...`}
                </div>
                <h2 style={{fontSize: '25px', fontWeight: '400', lineHeight: '1.3', textAlign: 'left'}}>
                    <div className='rcards'>
                        <div className='rcard'>
                            <ul className='a'>
                                <li><Point>Connect Google analytics</Point></li>
                                <li><Point>Generate Site map</Point></li>
                            </ul>
                        </div>
                        <div className='rcard'>
                            <ul className='a'>
                                <li><Point>Generate Robots.txt file</Point></li>
                                <li><Point>Generate Canonical URL</Point></li>
                            </ul>
                        </div>
                    </div>
                </h2>
            </Container>

            <Container style={{margin: '0 auto', width: screenWidth}}>
                <h1 style={{textAlign: 'center'}}>
                    <button onClick={() => window.open('https://webkit.onrender.com/')}>
                        Live Demo
                    </button>
                    <br/>
                    Don't use this kit cause it's free. Use it cause your time isn't free.
                </h1>
            </Container>

            <div style={{backgroundColor: '#2ecc7112'}}>
                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <Link
                        to='/webkit/launch'
                        style={{color: 'transparent'}}
                    >
                        <button
                            style={{margin: '100px 0 100px 0'}}
                        >
                            Start Setup
                        </button>
                    </Link>
                </Container>
            </div>

        </PageLayout>
    );
};

const Banner = ({windowWidth}) => {
    if (windowWidth > 480) {
        return (
            <>
                <h1 style={{textAlign: 'center', fontSize: '5vw', lineHeight: '1.5', fontWeight: '600'}}>
                    Ultimate Starter Kit For  <br/> Non-Technical SEO Consultants
                </h1>
            </>
        );
    } else {
        return (
            <>
                <h1 style={{fontSize: '9vw', textAlign: 'center', lineHeight: '1.3'}}>
                    Ultimate Starter Kit For  <br/> Non-Technical SEO Consultants
                </h1>
            </>
        );
    }
};

export default IndexPage;